<script lang="ts" setup>
import type { CartItemType } from '~/api/cart';
import placeholder from '~/assets/icons/placeholder.svg?url';

const TEST_ID = 'product-cart';

const { t } = useI18n();
const router = useRouter();
const commonStore = useCommonStore();
const productCartStore = useProductCartStore();
const subscribeProductsStore = useSubscribeProductsStore();

const isInfoModalVisible = ref(false);

const totalPriceAvailable = computed(() =>
  priceFormat(productCartStore.totalPriceAvailable),
);
const isAvailable = computed(() =>
  productCartStore.cartData.some((el) => el.isAvailable),
);

const getProductPicture = (productId: number) => {
  return (
    commonStore.products.find((item) => item.id === productId)?.picture ||
    placeholder
  );
};

const updateCartItem = (item: CartItemType, quantity: number) => {
  productCartStore.updateCartItem({ ...item, quantity });
};

const toOrder = () => {
  router.push('/order');
  productCartStore.close();
};

onMounted(() => {
  productCartStore.initCartData();
  subscribeProductsStore.fetchSubscribeProducts();
});

const filteredCartData = computed(() =>
  productCartStore.cartData.filter((item) => !item.isGift),
);
</script>

<template>
  <BaseSidebar
    v-model:visible="productCartStore.opened"
    tag="aside"
    :z-index="12"
    with-overlay
    class="productCart"
    content-class="productCart__modalContent"
  >
    <div class="productCart__container" :data-test-id="TEST_ID">
      <div class="productCart__header">
        {{ t('title') }}
        <span
          class="productCart__badge"
          :data-test-id="`${TEST_ID}-total-count`"
        >
          {{ productCartStore.totalCount }}
        </span>

        <button @click="productCartStore.close" class="productCart__close">
          <SvgoSimpleClose :font-controlled="false" />
        </button>
      </div>

      <div class="productCart__list">
        <template v-if="filteredCartData.length">
          <ProductCartItem
            v-for="item in filteredCartData"
            :key="item.skuId"
            :name="item.name"
            :code="item.code"
            :picture="getProductPicture(item.productId)"
            :price="item.price"
            :base-price="item.basePrice"
            :count="item.quantity"
            :is-available="item.isAvailable"
            :is-gift="item.isGift"
            @update:count="updateCartItem(item, $event)"
            @delete="updateCartItem(item, 0)"
          />
        </template>
        <p v-else class="productCart__empty">
          {{ t('empty') }}
        </p>
      </div>

      <div class="productCart__total">
        <span>{{ t('total') }}</span>
        <span>{{ totalPriceAvailable }}</span>
      </div>

      <template v-if="subscribeProductsStore.products.length">
        <div class="productCart__subscription">
          <span class="productCart__subscription_title">
            {{ t('discount', { value: SUBSCRIPTION_DISCOUNT_VALUE }) }}
          </span>
          <button
            @click="isInfoModalVisible = true"
            class="productCart__question"
          >
            {{ t('question') }}
          </button>
        </div>
        <div class="productCart__list">
          <ProductCartItem
            v-for="item in subscribeProductsStore.products"
            @delete="subscribeProductsStore.onDelete(item.id)"
            :key="item.id"
            :name="item.name"
            :code="item.code"
            :picture="item.picture || placeholder"
            :price="
              calculateSubscribeProductPrice(
                item.priceInfo.basePrice || item.priceInfo.price,
              )
            "
            :base-price="item.priceInfo.price"
            :additional="
              subscribeProductsStore.nextDate &&
              t('next_delivery', { date: subscribeProductsStore.nextDate })
            "
            :discount="SUBSCRIPTION_DISCOUNT_VALUE"
            mode="subscription"
          />
        </div>
      </template>

      <!--      TODO: Нужно ли еще-->
      <!--              <div class="productCart__subscription">-->
      <!--                <span class="productCart__subscription_title">-->
      <!--                  выберите бесплатные пробники-->
      <!--                </span>-->
      <!--                <span class="productCart__subscription_count">2 продукта</span>-->
      <!--              </div>-->
    </div>

    <!-- <ProductCartSamplers />-->

    <div class="productCart__container productCart__container_footer">
      <BaseButton
        @click="toOrder"
        :disabled="!isAvailable"
        dark
        block
        :data-test-id="`${TEST_ID}-to-order`"
      >
        {{ t('action') }}
      </BaseButton>
    </div>
  </BaseSidebar>

  <ClientOnly>
    <LazyProductSubscriptionInfoModal v-model:visible="isInfoModalVisible" />
  </ClientOnly>
</template>

<i18n>
ru:
  title: Корзина
  empty: У вас сейчас нет товаров в корзине
  total: Итого
  discount: Ваша подписка на рефил
  question: Как это работает?
  next_delivery: 'Приблизительная дата доставки: {date}'
  action: Оформить заказ

en:
  title: Cart
  empty: You have no items in your cart
  total: Total
  discount: Your subscription to the refil
  question: How does it work?
  next_delivery: 'Estimated delivery date: {date}'
  action: Checkout
</i18n>

<style lang="scss">
.productCart {
  width: 100%;
  max-width: 575px;
  background-color: #ffffff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

  @include mq('sm') {
    max-width: 100%;
    bottom: 0;
  }

  &__modalContent {
    display: flex;
    flex-direction: column;
  }

  &__openButton {
    top: 28px;
    right: -8px;
    position: absolute;
    transition: opacity 0.3s ease;

    @include mq('sm') {
      top: 6px;
      right: -10px;
    }

    &:hover {
      opacity: 0.4;
    }
  }

  &__container {
    padding: 0 28px;

    @include mq('sm') {
      padding: 0 16px;
    }

    &_footer {
      margin-top: auto;
      padding-top: 46px;
      padding-bottom: 35px;

      @include mq('md') {
        padding-top: 24px;
      }
    }
  }

  &__header {
    padding-top: 11px;
    display: flex;
    align-items: center;
    gap: 16px;
    height: 126px;
    border-bottom: 1px solid #121212;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 1;
    text-transform: uppercase;

    @include mq('sm') {
      height: auto;
      padding-top: 30px;
      padding-bottom: 17px;
    }
  }

  &__badge {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #121212;
    font-size: 13px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    display: block;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: auto;
    padding: 0;
    transition: opacity 0.3s ease;

    @include mq('sm') {
      margin-right: 4px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      width: 36px;
      height: 36px;
    }
  }

  &__list {
    padding-top: 29px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 29px;
  }

  &__empty {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #121212;
    margin: 0;
    text-align: center;
  }

  &__subscription {
    padding: 11px 0;
    border-bottom: 1px solid #121212;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #121212;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &_title {
      text-transform: uppercase;
    }

    &_count {
      white-space: nowrap;
    }
  }

  &__question {
    display: block;
    background: none;
    padding: 0;
    border: none;
    color: #121212;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;
    white-space: nowrap;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }

    @include mq('sm') {
      font-size: 12px;
      margin-top: auto;
    }
  }

  &__sampler {
    padding: 35px 0 19px;
    border-bottom: 1px solid #121212;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #121212;
    display: flex;
    justify-content: space-between;

    &_title {
      text-transform: uppercase;
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #121212;
    margin-bottom: 86px;
    padding: 24px 0;
    border-top: 1px solid #121212;
    border-bottom: 1px solid #121212;

    @include mq('sm') {
      margin-bottom: 64px;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}
</style>
